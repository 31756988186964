import priips from '../Prospectus/7/priipsKid.pdf'
import priipsGerman from '../Prospectus/7/priipsKidGerman.pdf'
import priipsFrench from '../Prospectus/7/priipsKidFrench.pdf'
import priipsItalian from '../Prospectus/7/priipsKidItalian.pdf'
import priipsSpanish from '../Prospectus/7/priipsKidSpanish.pdf'
import prospectus from '../Prospectus/7/prospectus.pdf'
import prospectusGerman from '../Prospectus/7/prospectusGerman.pdf'
import EUR from '../Prospectus/6/EUR.pdf'
import GBP from '../Prospectus/6/GBP.pdf'
import USD from '../Prospectus/6/USD.pdf'
import GBP_LR from '../Prospectus/6/GBP_LR.pdf'
import USD_LR from '../Prospectus/6/USD_LR.pdf'

export default function Prospectus({ fund }) {
    const style = { paddingLeft: '8px' };

    const renderContent = () => {
        switch (fund.id) {
            case 7:
                return (
                    <div>
                        <h4 style={style}><strong>ENGLISH VERSION</strong></h4>
                        <p style={style}><a href={priips} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>Otus Smaller Companies UCITS Fund PRIIPS KID</span></a></p>
                        <p style={style}><a href={prospectus} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>Otus Smaller Companies UCITS Fund Prospectus</span></a></p>
                        <h4 style={style}><strong>GERMAN INVESTORS</strong></h4>
                        <p style={style}><a href={priipsGerman} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>Smaller Companies UCITS Fund PRIIPS KID - German Translation</span></a></p>
                        <p style={style}><a href={prospectusGerman} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>Smaller Companies UCITS Fund Prospectus & Addendum - Consolidated for Germany</span></a></p>
                        <h4 style={style}><strong>FRENCH INVESTORS</strong></h4>
                        <p style={style}><span style={{ textDecoration: 'underline' }}><a href={priipsFrench} target="_blank">Smaller Companies UCITS Fund PRIIPS KID - French Translation</a></span></p>
                        <h4 style={style}><strong>ITALIAN&nbsp;</strong><strong>INVESTORS</strong></h4>
                        <p style={style}><span style={{ textDecoration: 'underline' }}><a href={priipsItalian} target="_blank">Smaller Companies UCITS Fund PRIIPS KID - Italian Translation</a></span></p>
                        <h4 style={style}><strong>SPANISH&nbsp;</strong><strong>INVESTORS</strong></h4>
                        <p style={style}><span style={{ textDecoration: 'underline' }}><a href={priipsSpanish} target="_blank">Smaller Companies UCITS Fund PRIIPS KID - Spanish Translation</a></span></p>
                        <p style={style}>&nbsp;</p>
                    </div >
                );
            case 6:
                return (
                    <div>
                        <h4 style={style}><strong>SHARE CLASSES</strong></h4>
                        <p style={style}><a href={EUR} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>EUR</span></a></p>
                        <p style={style}><a href={GBP} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>GBP</span></a></p>
                        <p style={style}><a href={USD} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>USD</span></a></p>
                        <p style={style}><a href={GBP_LR} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>GBP LR</span></a></p>
                        <p style={style}><a href={USD_LR} target="_blank" rel="noopener noreferrer"><span style={{ textDecoration: 'underline' }}>USD LR</span></a></p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ padding: '8px 8px', backgroundColor: 'white' }}>
            <h2>PRIIPS KID & Prospectus</h2>
            <hr />
            {renderContent()}
        </div>
    );
}
